var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center"},[_c('h1',{staticClass:"font-weight-bolder mr-1"},[_vm._v(" "+_vm._s(_vm.$t('new', { type: _vm.$t('expense') }))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$route.query.createType === 'direct_cost' ? _vm.$t('directCost') : _vm.$t('additivesAndChanges'))+" ")])])],1),_c('validation-observer',{ref:"expensesValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_vm._l((_vm.expense.expense_records),function(record,index){return _c('b-card',{key:index,attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0 mt-1 mx-1 d-flex align-items-start justify-content-between"},[_c('span',{staticClass:"h4 text-primary d-flex"},[_vm._v(" "+_vm._s(_vm.$t('expense'))+" "+_vm._s(index + 1)+" ")]),_c('b-card-text',{staticClass:"font-medium-6 mb-0"},[_c('feather-icon',{staticClass:"cursor-pointer ml-1",attrs:{"icon":"XIcon","size":"15"},on:{"click":function($event){return _vm.spliceExpense(index)}}})],1)],1),_c('b-row',[_c('b-col',[_c('b-card-body',{staticClass:"pt-0"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('expenseType')}},[_c('validation-provider',{attrs:{"name":_vm.$t('expenseType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"font-weight-bolder",attrs:{"options":_vm.expensesTypes,"size":"lg"},model:{value:(record.expense_type_catalog_id),callback:function ($$v) {_vm.$set(record, "expense_type_catalog_id", $$v)},expression:"record.expense_type_catalog_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),(_vm.materialCatalogId == record.expense_type_catalog_id)?_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('material')}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('material')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"autocomplete":"off","type":"text"},model:{value:(record.name),callback:function ($$v) {_vm.$set(record, "name", $$v)},expression:"record.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{},[_c('vue-bootstrap-typeahead',{attrs:{"placeholder":_vm.$t('search'),"size":"lg","data":_vm.productsResults.concat( _vm.groupedExpenses),"serializer":function (item) { return item.name; },"show-all-results":true,"max-matches":5},on:{"hit":function($event){return _vm.selectProduct($event, index)}},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
var data = ref.data;
return [_c('div',[_c('span',{staticClass:"font-weight-bolder mr-1",staticStyle:{"font-size":"14px"}},[_vm._v("["+_vm._s(data.sku)+"]")]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(data.name))])])]}}],null,true),model:{value:(_vm.productQuery),callback:function ($$v) {_vm.productQuery=$$v},expression:"productQuery"}})],1)],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('supplier')}},[_c('vue-bootstrap-typeahead',{attrs:{"placeholder":_vm.$t('search'),"size":"lg","data":_vm.suppliersResults,"serializer":function (name) { return name; },"max-matches":6},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
var data = ref.data;
return [_c('div',[_c('span',[_vm._v(_vm._s(data))])])]}}],null,true),model:{value:(record.supplier),callback:function ($$v) {_vm.$set(record, "supplier", $$v)},expression:"record.supplier"}})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.newMaterial",modifiers:{"newMaterial":true}}],staticClass:"mt-1",attrs:{"size":"lg","variant":"outline-primary","block":""},on:{"click":function($event){_vm.newMaterialIndex = index}}},[_vm._v(_vm._s(_vm.$t('newMaterial')))])],1),(record.name)?_c('b-col',{attrs:{"sm":"12"}},[_c('b-card',{staticClass:"mb-1 mt-1 mt-lg-0 py-1 border-secondary",class:{
                      'w-50': !_vm.isSmallScreen,
                      'w-100': _vm.isSmallScreen,
                    },attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"py-0"},[_c('div',{staticClass:"d-flex justify-content-between"},[(record.name)?_c('div',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('name'))+": ")]),_vm._v(" "+_vm._s(record.name)+" ")]):_vm._e(),_c('feather-icon',{staticClass:"cursor-pointer ml-1",attrs:{"icon":"XIcon","size":"15","color":"rgb(255,0,0)"},on:{"click":function($event){return _vm.removeProduct(index)}}})],1),(record.brand)?_c('div',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('brand'))+": ")]),_vm._v(" "+_vm._s(record.brand)+" ")]):_vm._e(),(record.description)?_c('div',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('description'))+": ")]),_vm._v(" "+_vm._s(record.description)+" ")]):_vm._e(),(record.sku)?_c('div',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" SKU: ")]),_vm._v(" "+_vm._s(record.sku)+" ")]):_vm._e(),(record.unit_type)?_c('div',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('unit'))+": ")]),_vm._v(" "+_vm._s(_vm.$tc(_vm.unitsFilter(record.unit_type)))+" ")]):_vm._e()])],1)],1):_vm._e(),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('quantity')}},[_c('validation-provider',{attrs:{"name":_vm.$t('quantity'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"number","step":".01"},model:{value:(record.quantity),callback:function ($$v) {_vm.$set(record, "quantity", $$v)},expression:"record.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('unitPrice')}},[_c('validation-provider',{attrs:{"name":_vm.$t('unitPrice'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$","size":"lg"}},[_c('money',_vm._b({staticClass:"font-weight-bolder px-1 flex-fill form-control",model:{value:(record.unit_price),callback:function ($$v) {_vm.$set(record, "unit_price", $$v)},expression:"record.unit_price"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('iva')}},[_c('validation-provider',{attrs:{"name":_vm.$t('iva'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"number"},model:{value:(record.iva_percentage),callback:function ($$v) {_vm.$set(record, "iva_percentage", $$v)},expression:"record.iva_percentage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('subtotal')}},[_c('b-input-group',{attrs:{"prepend":"$","size":"lg"}},[_c('money',_vm._b({staticClass:"font-weight-bolder px-1 flex-fill form-control",attrs:{"disabled":"","value":record.unit_price * record.quantity}},'money',_vm.money,false))],1)],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('total')}},[_c('b-input-group',{attrs:{"prepend":"$","size":"lg"}},[_c('money',_vm._b({staticClass:"font-weight-bolder px-1 flex-fill form-control",attrs:{"disabled":"","value":record.unit_price * record.quantity +
                            record.unit_price *
                            record.quantity *
                            (record.iva_percentage / 100)}},'money',_vm.money,false))],1)],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('date')}},[_c('validation-provider',{attrs:{"name":_vm.$t('date'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"font-weight-bolder",attrs:{"date-format-options":{
                          year: '2-digit',
                          month: '2-digit',
                          day: '2-digit',
                        },"size":"lg"},model:{value:(record.expense_date),callback:function ($$v) {_vm.$set(record, "expense_date", $$v)},expression:"record.expense_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('status')}},[_c('b-dropdown',{staticClass:"m-md-2",class:_vm.backgroundForStatus(record.expense_status),attrs:{"id":"dropdown-1","text":_vm.$t(record.expense_status),"variant":"transparent","size":"lg"}},_vm._l((_vm.expenseStatusOptions),function(status,key){return _c('b-dropdown-item',{key:key,on:{"click":function($event){return _vm.setStatus(record, status.value)}}},[_vm._v(" "+_vm._s(_vm.$t(status.value))+" ")])}),1)],1)],1)],1):(
                  _vm.materialCatalogId != record.expense_type_catalog_id &&
                    record.expense_type_catalog_id
                )?_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('date')}},[_c('validation-provider',{attrs:{"name":_vm.$t('date'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"font-weight-bolder",attrs:{"date-format-options":{
                          year: '2-digit',
                          month: '2-digit',
                          day: '2-digit',
                        },"size":"lg"},model:{value:(record.expense_date),callback:function ($$v) {_vm.$set(record, "expense_date", $$v)},expression:"record.expense_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('supplier')}},[_c('b-form-input',{staticClass:"qty-field",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(record.supplier),callback:function ($$v) {_vm.$set(record, "supplier", $$v)},expression:"record.supplier"}})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('status')}},[_c('b-dropdown',{staticClass:"m-md-2",class:_vm.backgroundForStatus(record.expense_status),attrs:{"id":"dropdown-1","text":_vm.$t(record.expense_status),"variant":"transparent","size":"lg"}},_vm._l((_vm.expenseStatusOptions),function(status,key){return _c('b-dropdown-item',{key:key,on:{"click":function($event){return _vm.setStatus(record, status.value)}}},[_vm._v(" "+_vm._s(_vm.$t(status.value))+" ")])}),1)],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('description')}},[_c('validation-provider',{attrs:{"name":_vm.$t('description'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(record.description),callback:function ($$v) {_vm.$set(record, "description", $$v)},expression:"record.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('quantity')}},[_c('validation-provider',{attrs:{"name":_vm.$t('quantity'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"number"},model:{value:(record.quantity),callback:function ($$v) {_vm.$set(record, "quantity", $$v)},expression:"record.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('unitPrice')}},[_c('validation-provider',{attrs:{"name":_vm.$t('unitPrice'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"number"},model:{value:(record.unit_price),callback:function ($$v) {_vm.$set(record, "unit_price", $$v)},expression:"record.unit_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('iva')}},[_c('validation-provider',{attrs:{"name":_vm.$t('iva'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"number"},model:{value:(record.iva_percentage),callback:function ($$v) {_vm.$set(record, "iva_percentage", $$v)},expression:"record.iva_percentage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('subtotal')}},[_c('b-input-group',{attrs:{"prepend":"$","size":"lg"}},[_c('money',_vm._b({staticClass:"font-weight-bolder px-1 flex-fill form-control",attrs:{"disabled":"","value":record.unit_price * record.quantity}},'money',_vm.money,false))],1)],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('total')}},[_c('b-input-group',{attrs:{"prepend":"$","size":"lg"}},[_c('money',_vm._b({staticClass:"font-weight-bolder px-1 flex-fill form-control",attrs:{"disabled":"","value":record.unit_price * record.quantity +
                            record.unit_price *
                            record.quantity *
                            (record.iva_percentage / 100)}},'money',_vm.money,false))],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","size":"lg"},on:{"click":_vm.pushExpense}},[_vm._v(" "+_vm._s(_vm.$t('addAnotherExpenses'))+" ")]),_c('b-button',{staticClass:"px-5",attrs:{"variant":"primary","size":"lg","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],2)],1),_c('b-modal',{attrs:{"id":"newMaterial","title":_vm.$t('newMaterial')},on:{"ok":function($event){return _vm.selectNewMaterial()},"cancel":function($event){_vm.newMaterial = {}},"hide":function($event){_vm.newMaterialIndex = null}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('name'),"label-for":"input-sm"}},[_c('b-form-input',{staticClass:"qty-field",attrs:{"min":"1","autocomplete":"off","type":"text"},model:{value:(_vm.newMaterial.name),callback:function ($$v) {_vm.$set(_vm.newMaterial, "name", $$v)},expression:"newMaterial.name"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('brand'),"label-for":"input-sm"}},[_c('b-form-input',{staticClass:"qty-field",attrs:{"min":"1","autocomplete":"off","type":"text"},model:{value:(_vm.newMaterial.brand),callback:function ($$v) {_vm.$set(_vm.newMaterial, "brand", $$v)},expression:"newMaterial.brand"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('description'),"label-for":"input-sm"}},[_c('b-form-input',{staticClass:"qty-field",attrs:{"min":"1","autocomplete":"off","type":"text"},model:{value:(_vm.newMaterial.description),callback:function ($$v) {_vm.$set(_vm.newMaterial, "description", $$v)},expression:"newMaterial.description"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }